<template>
  <div>
    <div class="dl" v-for="(item, index) in items" :key="index">
      <div class="dt">
        <div class="input-radio">
          <input
            name="value"
            required
            :value="item.id"
            type="radio"
            :id="item.id"
            v-model="data.id"
          />
          <span class="checkmark"></span>
        </div>
      </div>
      <div class="dd">
        <label :for="item.id">{{ item.text }}</label>
      </div>
    </div>
    <div class="dl" v-if="other">
      <div class="dt">
        <div class="input-radio">
          <input
            name="value"
            required
            :value="false"
            v-model="data.id"
            type="radio"
            id="other"
          />
          <span class="checkmark"></span>
        </div>
      </div>
      <div class="dd">
        <label :for="other"> {{ $t("feedback.other") }}</label>
        <input
          type="text"
          name="other"
          :required="data.id === false"
          v-model="data.otherValue"
          :placeholder="$t('feedback.comments')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Radiobutton",
  data: () => {
    return {
      data: {
        id: -1,
        otherValue: ""
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    getAnswer() {
      return {
        questionId: this.questionId,
        optionValue: this.data.id || this.data.otherValue,
        optionId: this.data.id
      };
    }
  }
};
</script>

<style scoped></style>
